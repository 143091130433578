<template>
  <v-card :height="cardHeight">
    <v-card-title> Total Non billable hours by month </v-card-title>
    <v-row>
      <v-spacer />
      <v-col cols="11">
        <GChart
          :settings="{ packages: ['line'] }"
          :data="chartData"
          :options="chartOptions"
          :createChart="(el, google) => new google.charts.Line(el)"
          @ready="onChartReady"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="11">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <!-- TO ensure that the table start with a month and not the heading  -->
                <template v-for="item in chartData.slice(0)">
                  <th class="text-left" :key="item[0]">{{ item[0] }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr key="Vacation">
                <template v-for="item in chartData.slice(0)">
                  <td :key="item[0]">{{ item[1] }}</td>
                </template>
              </tr>
              <tr key="Sickness">
                <template v-for="item in chartData.slice(0)">
                  <td :key="item[0]">{{ item[2] }}</td>
                </template>
              </tr>
              <tr key="VAB">
                <template v-for="item in chartData.slice(0)">
                  <td :key="item[0]">{{ item[3] }}</td>
                </template>
              </tr>
              <tr key="Parental leave">
                <template v-for="item in chartData.slice(0)">
                  <td :key="item[0]">{{ item[4] }}</td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-spacer />
    </v-row>
  </v-card>
</template>

<script>
import { GChart } from "vue-google-charts";
export default {
  name: "YearNonBillableHoursCard",
  components: { GChart },
  props: {
    cardHeight: String,
    year: String
  },
  data: () => ({
    chartsLib: null,
  }),
  computed: {
    chartOptions() {
      if (!this.chartsLib) return null;
      return this.chartsLib.charts.Line.convertOptions({
        chart: {
          title: "Billable Hours",
          // subtitle: "A Subtitle",
        },
        colors: ["#64a48c", "#a4647c", "#8c64a4"],
      });
    },
    chartData() {
      let tr = this.$store.state.timeReports.timeReports;
      let chartData = [
        ["", "Vacation", "Sickness", "VAB", "Parental leave"],
        ["Jan.", 0, 0, 0, 0],
        ["Feb.", 0, 0, 0, 0],
        ["Mar.", 0, 0, 0, 0],
        ["Apr.", 0, 0, 0, 0],
        ["May", 0, 0, 0, 0],
        ["June", 0, 0, 0, 0],
        ["July", 0, 0, 0, 0],
        ["Aug.", 0, 0, 0, 0],
        ["Sep.", 0, 0, 0, 0],
        ["Oct.", 0, 0, 0, 0],
        ["Nov.", 0, 0, 0, 0],
        ["Dec.", 0, 0, 0, 0],
      ];

      let getHours = function (timeReport,y ) {
        if (
          timeReport !== "Normal" &&
          timeReport.month.split("-")[0] == y
        ) {
          // chartData[month][1] = chartData[month][1] + parseInt(tr[t].hours, 10);
          return parseInt(timeReport.hours, 10);
        } else {
          return 0;
        }
      };

      for (var t in tr) {
        let monthInt = parseInt(tr[t].month.split("-")[1]);
        switch (tr[t].type) {
          case "Vacation":
            chartData[monthInt][1] = chartData[monthInt][1] + getHours(tr[t], this.year);
            break;
          case "Sickness":
            chartData[monthInt][2] = chartData[monthInt][2] + getHours(tr[t], this.year);
            break;
          case "Care of child (VAB)":
            chartData[monthInt][3] = chartData[monthInt][3] + getHours(tr[t], this.year);
            break;
          case "Parental leave":
            chartData[monthInt][4] = chartData[monthInt][4] + getHours(tr[t], this.year)
            break
          default:
            break;
        }
      }
      return chartData;
    },
  },
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
  },
};
</script>